/* Import Roboto Font */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
/* Import Montserrat Font */
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700&display=swap');

/* Set up basic page */
* {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  background-color: #282c34;
  color: white;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
}

.App-header {

  text-align: center;
  background-color: #282c34;
  color: white;
  justify-content: center;
  padding: 1rem;

}

.App-header h1 {
  font-size: 5em;
  letter-spacing: -2px;
  border-bottom: 2px solid white;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
}

.Gallery {
  width: 80%;

  margin: 0 auto;

}


